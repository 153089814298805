define("discourse/plugins/discourse-gamification/discourse/routes/gamification-leaderboard-by-name", ["exports", "discourse/routes/discourse", "discourse/lib/ajax"], function (_exports, _discourse, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model(params) {
      return (0, _ajax.ajax)(`/leaderboard/${params.leaderboardId}`).then(scores => {
        return scores;
      }).catch(() => this.replaceWith("/404"));
    }
  });
});